import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const Gallery = ({ projectGallery }) => {
  return (
    <div className="mb-12 lg:mb-20">
      {projectGallery.map(item => (
        <figure key={item.id} className="mb-8 lg:mb-16">
          <GatsbyImage
            image={item?.image?.gatsbyImageData}
            alt={item?.image?.title ? item?.image?.title : ""}
          />
          <figcaption className="mt-4 w-full flex items-center justify-between px-3">
              <div className="text-xs text-center uppercase tracking-widest text-gray-400">
                {item?.titleLeft}
              </div>
              <div className="text-xs text-center uppercase tracking-widest text-gray-400">
                {item?.titleRight}
              </div>
          </figcaption>
        </figure>
      ))}
    </div>
  )
}

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
}

export default Gallery
