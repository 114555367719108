import React from "react"
import PropTypes from "prop-types"
import Grid from "../grid"

const ProjectTitle = ({ title, subtitle }) => {
  return (
    <Grid>
      <div className="col-span-1">
        <h1 className="text-lg lg:text-xl font-light text-gold tracking-widest mb-1">
          {title}
        </h1>
        <h2 className="text-sm text-gold tracking-widest mb-10">{subtitle}</h2>
      </div>
    </Grid>
  )
}

ProjectTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ProjectTitle
