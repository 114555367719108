import React from "react"
import PropTypes from "prop-types"
import Grid from "./grid"
import ProjectCard from "./cards/project-card"
import randomizeItems from "../helpers/randomizeItems"

const OtherProjects = ({ projects, title = "Andere Projekte" }) => {
  const randomProjects = randomizeItems(projects, 3)
  return (
    <>
      {randomProjects.length > 0 ? (
        <>
          <h3 className="uppercase text-base text-center lg:text-xl tracking-wider text-gold mb-6 lg:mb-10">
            {title}
          </h3>
          <Grid>
            {randomProjects.map(project => (
              <ProjectCard
                key={project?.node.id}
                image={project?.node?.previewImage}
                title={project?.node?.title}
                subtitle={project?.node?.subtitle}
                slug={`/projekte/${project?.node?.slug}`}
              />
            ))}
          </Grid>
        </>
      ) : null}
    </>
  )
}

OtherProjects.propTypes = {
  projects: PropTypes.array.isRequired,
}

export default OtherProjects
