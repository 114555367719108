import React from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { convertRaw, renderRawText } from "../../helpers/rich-text"

const ProjectDescription = ({ description }) => {
  return (
    <div className="flex flex-col order-2 md:order-1">
      
      <div className="text-xs tracking-widest text-gray-500">
        {documentToReactComponents(convertRaw(description), renderRawText)}
      </div>
    </div>
  )
}

ProjectDescription.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.object,
}

export default ProjectDescription
