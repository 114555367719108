import React from "react"
import PropTypes from "prop-types"

const KeyData = ({
  builder,
  use,
  cost,
  bgf,
  hz,
  construction,
  subcontractors,
  financialResources,
  planer,
  service,
  period,
  award,
}) => {
  return (
    <div className="grid gap-y-3 grid-cols-3 auto-rows-max break-words pl-4 pr-2 order-3 md:order-2">
      {builder && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Bauherr:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {builder}
          </div>
        </>
      )}
      {use && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Nutzung:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {use}
          </div>
        </>
      )}
      {cost && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Kosten:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {cost}
          </div>
        </>
      )}
      {bgf && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            BGF:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {bgf}
          </div>
        </>
      )}
      {hz && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            HZ:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {hz}
          </div>
        </>
      )}
      {construction && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Hochbau:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {construction}
          </div>
        </>
      )}
      {subcontractors && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Subunternehmer:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {subcontractors}
          </div>
        </>
      )}
      {financialResources && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Freiraum:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {financialResources}
          </div>
        </>
      )}
      {planer && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Planer:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {planer}
          </div>
        </>
      )}
      {service && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Leistung:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {service}
          </div>
        </>
      )}
      {period && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Zeitraum:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {period}
          </div>
        </>
      )}
      {award && (
        <>
          <div className="col-span-1 uppercase text-xs text-gray-600 tracking-wide">
            Auszeichnung:
          </div>
          <div className="col-span-2 pl-2 md:pl-3 text-xs text-gray-500 tracking-widest">
            {award}
          </div>
        </>
      )}
    </div>
  )
}

KeyData.propTypes = {
  builder: PropTypes.string,
  use: PropTypes.string,
  cost: PropTypes.string,
  bgf: PropTypes.string,
  hz: PropTypes.string,
  construction: PropTypes.string,
  service: PropTypes.string,
  period: PropTypes.string,
  award: PropTypes.string,
}

export default KeyData
