import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import Grid from "../components/grid"
import ProjectTitle from "../components/project/project-title"
import ProjectDescription from "../components/project/project-description"
import KeyData from "../components/project/key-data"
import Gallery from "../components/project/gallery"
import OtherProjects from "../components/other-projects"


const ProjectTemplate = ({
  path,
  data: {
    project: {
      title,
      subtitle,
      description,
      detailImage,
      projectGallery,
      builder,
      use,
      cost,
      bgf,
      hz,
      construction,
      subcontractors,
      financialResources,
      planer,
      service,
      period,
      award,
    },
    allProjects,
  },
}) => {

  return (
    <Layout path={path}>
      <Seo title={title} />
      <Container verticalSpaceSm>
        <ProjectTitle title={title} subtitle={subtitle} />
        <Grid>
          <ProjectDescription description={description} />
          <KeyData
            builder={builder}
            use={use}
            cost={cost}
            bgf={bgf}
            hz={hz}
            construction={construction}
            subcontractors={subcontractors}
            financialResources={financialResources}
            planer={planer}
            service={service}
            period={period}
            award={award}
          />
          {detailImage && (
            <div className="order-1 md:order-3 relative">
              <GatsbyImage
                className="aspect-w-1 aspect-h-1"
                image={detailImage?.gatsbyImageData}
                alt={detailImage?.title ? detailImage?.title : ""}
              />
            </div>
          )}
        </Grid>
      </Container>
      <Container fullWidth maxWidth5xl>
        <Gallery projectGallery={projectGallery} />
      </Container>
      <Container verticalSpaceSm>
        <OtherProjects projects={allProjects.edges} />
      </Container>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectById($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    project: contentfulProjekt(id: { eq: $id }) {
      award
      bgf
      builder
      construction
      hz
      subcontractors
      financialResources
      planer
      cost
      period
      service
      use
      title
      subtitle
      description {
        raw
      }
      detailImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          quality: 40
        )
        id
        title
      }
      projectGallery {
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            quality: 40
          )
        }
        id
        titleLeft
        titleRight
      }
    }
    allProjects: allContentfulProjekt(filter: { id: { ne: $id } }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          previewImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              quality: 40
            )
          }
        }
      }
    }
  }
`
